<template>
  <v-row class="justify-center align-self-start">
    <v-col cols="12" md="8" lg="6">
      <v-card outlined :loading="loading">
        <v-card-title class="grey lighten-3 flex-column align-start">
          <div>Licences</div>
          <div class="text-caption text-break">
            Chaque adresse doit disposer d'au moins une licence. Une license
            vous permet de connecter autant d'appareil que vous le souhaitez
            (PC, tablettes...). Cependant, vous ne pouvez traiter qu'une seule
            photo en simultané par licence. S'il vous arrive de gérer plusieurs
            clients au même moment sur différents appareils, vous aurez besoin
            d'autant de licences que de photos gérées en même temps.
          </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6">
              <v-select
                label="Adresse"
                class="mb-2"
                v-model="addressId"
                :items="addresses"
                item-value="id"
                :item-text="
                  item => `${item.name} - ${item.city} - ${item.address}`
                "
                outlined
                dense
                hide-details
              ></v-select>
            </v-col>
          </v-row>
          <v-divider class="mb-2"></v-divider>
          <template v-if="formattedSubscriptions.length">
            <div class="text-center mb-2">
              <v-data-table
                :headers="headers"
                :items="formattedSubscriptions"
                :items-per-page="5"
                class="elevation-0"
                :hide-default-footer="formattedSubscriptions.length < 6"
                no-data-text="Cette adresse n'a aucune licence"
                :options="{ sortBy: ['id'], sortDesc: [true] }"
              >
                <template #item.price="{item, value}">
                  <div class="text-center">
                    <div>{{ value }}</div>
                    <div
                      v-if="trialDays > 0 && item.usable"
                      class="text-caption"
                    >
                      {{ trialDays }} jours d'essai restants
                    </div>
                  </div>
                </template>
                <template #item.status="{item, value}">
                  <div>
                    <v-badge
                      dot
                      left
                      inline
                      :color="item.usable ? 'success' : 'error'"
                      >{{ value }}</v-badge
                    >
                  </div>
                </template>
                <template #item.actions="{item}">
                  <v-btn
                    x-small
                    @click="editSubscription(item.id)"
                    :disabled="!item.editable"
                  >
                    Modifier
                  </v-btn>
                </template>
              </v-data-table>
            </div>
            <v-divider class="mb-2"></v-divider>
          </template>

          <div class="subtitle-2">Ajouter des licences</div>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-select
                label="Nombre de licence à ajouter"
                outlined
                dense
                :items="Array.from({ length: 6 }, (_, i) => i)"
                v-model="form.quantity.value"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="8">
              <v-select
                v-show="form.quantity.value > 0"
                label="Moyen de paiement"
                outlined
                dense
                :items="paymentMethods"
                :item-text="formatPaymentMethodName"
                item-value="id"
                v-model="form.paymentMethod.value"
                no-data-text="Vous n'avez ajouté aucune méthode de paiement. Cliquez sur le bouton ci-dessous."
              >
                <template #append-item>
                  <v-list-item dense @click="newPaymentMethodDialog = true">
                    <v-list-item-content>
                      <v-list-item-title>
                        Ajouter un moyen de paiement
                        <v-icon color="black">mdi-plus-circle</v-icon>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-divider class="my-2"></v-divider>
          <div>
            <div v-if="firstPrice > 0">
              <span class="font-weight-bold">Tarif : </span>
              {{ firstPrice }}€ HT / mois la première licence puis
              {{ nextPrice }}€ HT / mois les licences supplémentaires
            </div>
            <div class="text-h6 text-decoration-underline">Total</div>
            <div>
              <span class="font-weight-bold">Actuellement : </span>
              {{ price.actualHT }}€ HT / mois
            </div>
            <template v-if="form.quantity.value > 0">
              <div class="font-weight-bold">Avec vos nouvelles licences :</div>
              <div class="text-h4">
                {{ price.futureHT }}€ HT / mois
                <span class="text-h6">({{ price.futureTTC }}€ TTC)</span>
              </div>
            </template>
            <div>Sans engagement</div>
            <div v-if="trialDays > 0" class="success--text">
              {{ trialDays }} jours offerts (vous ne serez pas débités avant la
              fin de cette période)
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn dark :to="{ name: 'Home' }" exact>
            <v-icon left>mdi-chevron-left</v-icon>
            Retour
          </v-btn>
          <v-btn
            color="success"
            class="ml-auto"
            :disabled="
              form.quantity.value === 0 || form.paymentMethod.value === null
            "
            :loading="submitLoading"
            @click="submitSubscriptions"
          >
            <span v-if="form.quantity.value === 0">Confirmer</span>
            <span v-else-if="form.quantity.value === 1"
              >Confirmer la création d'une nouvelle licence</span
            >
            <span v-else
              >Confirmer la création de {{ form.quantity.value }} nouvelles
              licences</span
            >
          </v-btn>
        </v-card-actions>
      </v-card>
      <div class="text-center mt-1">
        Une question ? Contactez-nous via
        <a href="mailto:contact@cdap.photo">contact@cdap.photo</a>
      </div>
    </v-col>
    <v-dialog max-width="500px" v-model="newPaymentMethodDialog">
      <v-card>
        <v-card-title>
          Nouveau moyen de paiement
          <v-btn icon class="ml-auto" @click="newPaymentMethodDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="text-overline mb-2">Carte bancaire</div>
          <stripe-element-card
            ref="stripeElement"
            :pk="pulishableKey"
            @token="tokenCreated"
            :elementStyle="stripeElementStyle"
          ></stripe-element-card>
          <div v-if="newPaymentMethodError" class="error--text">
            {{ newPaymentMethodError }}
          </div>
          <div class="text--secondary">
            Sécurisé par Stripe. Vos coordonnées bancaires ne sont pas stockées
            sur nos serveurs.
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="success"
            class="ml-auto"
            @click="submitPaymentMethod"
            :loading="newPaymentMethodLoading"
          >
            Ajouter
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog max-width="500px" v-model="editSubscriptionDialog">
      <v-card>
        <v-card-title>
          Modifier la licence
          <v-btn icon class="ml-auto" @click="editSubscriptionDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="text-overline mb-2">Modifier le moyen de paiement</div>
          <v-select
            label="Moyen de paiement"
            outlined
            dense
            :items="paymentMethods"
            :item-text="formatPaymentMethodName"
            item-value="id"
            v-model="editSubscriptionForm.paymentMethod.value"
            no-data-text="Vous n'avez ajouté aucune méthode de paiement. Cliquez sur le bouton ci-dessous."
          >
            <template #append-item>
              <v-list-item dense @click="newPaymentMethodDialog = true">
                <v-list-item-content>
                  <v-list-item-title>
                    Ajouter un moyen de paiement
                    <v-icon color="black">mdi-plus-circle</v-icon>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-select>
          <div class="text-overline mb-2">Supprimer cette licence</div>
          <v-switch
            color="error"
            label="Ne pas renouveler la licence"
            class="mt-0"
            :input-value="editedSubscriptionActive"
            :value="editedSubscriptionActive"
            @change="editSubscriptionForm.active.value = !$event"
          ></v-switch>
          <div class="text--secondary">
            Si vous choisissez de ne pas la renouveler, votre licence se
            terminera à la fin de la période en cours.
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            class="ml-auto"
            @click="editSubscriptionDialog = false"
            :disabled="editedSubscriptionLoading"
            text
          >
            Annuler
          </v-btn>
          <v-btn
            color="success"
            text
            @click="submitEditedSubscription"
            :loading="editedSubscriptionLoading"
          >
            Enregistrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog max-width="500px" v-model="confirmationDialog">
      <v-card>
        <v-card-title>
          Confirmation
          <v-btn icon class="ml-auto" @click="confirmationDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-alert type="success" class="mt-4"
            >Vos licences ont bien été activées !</v-alert
          >
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text @click="confirmationDialog = false"
            >Ajouter une autre licence</v-btn
          >
          <v-btn color="success" text class="ml-auto" @click="terminate" exact
            >Terminer</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { StripeElementCard } from '@vue-stripe/vue-stripe'
import { requestService } from '@/services/request.service'
import Validation from '@/components/mixins/Validation'

export default {
  name: 'Licences',
  components: { StripeElementCard },
  mixins: [Validation],
  data() {
    this.pulishableKey = process.env.VUE_APP_STRIPE_PUBLIC_KEY
    return {
      loading: false,
      form: {
        quantity: {
          value: 0,
        },
        paymentMethod: {
          value: null,
        },
      },
      basePrice: 24.99,
      newPaymentMethodDialog: false,
      newPaymentMethodLoading: false,
      newPaymentMethodError: null,
      addressId: null,
      addresses: [],
      paymentMethods: [],
      stripeElementStyle: {
        base: {
          color: '#000',
        },
      },
      headers: [
        { text: 'Numéro', value: 'id', width: '100px' },
        { text: 'Tarif', value: 'price', align: 'center' },
        {
          text: 'Méthode de paiement',
          value: 'paymentMethod',
          align: 'center',
        },
        { text: 'Status', value: 'status', align: 'center' },
        { text: '', value: 'actions', sortable: false, align: 'center' },
      ],
      subscriptions: [],
      trialDays: 0,
      submitLoading: false,
      editSubscriptionDialog: false,
      editedSubscription: null,
      editedSubscriptionLoading: false,
      editSubscriptionForm: {
        paymentMethod: {
          value: null,
        },
        active: {
          value: null,
        },
      },
      confirmationDialog: false,
      firstPrice: 0,
      nextPrice: 0,
    }
  },
  computed: {
    price() {
      let total = 0
      for (const subscription of this.subscriptions) {
        if (subscription.usable) {
          total += subscription.price.amount_ht
        }
      }
      const actualHT = total.toFixed(2)
      let futurePriceHt = 0
      if (this.form.quantity.value === 1) {
        futurePriceHt = this.basePrice
      } else if (this.form.quantity.value > 1) {
        futurePriceHt =
          this.basePrice + this.nextPrice * (this.form.quantity.value - 1)
      }
      const futureHT = (total + futurePriceHt).toFixed(2)
      return {
        actualHT: actualHT,
        futureHT: futureHT,
        actualTTC: (actualHT * 1.2).toFixed(2),
        futureTTC: (futureHT * 1.2).toFixed(2),
      }
    },
    formattedSubscriptions() {
      return this.subscriptions.map(subscription => {
        let status = 'Active'
        if (!subscription.usable) {
          status = `Terminée le ${this.formatDate(
            new Date(subscription.end_at),
          )}`
        } else if (subscription.end_at !== null) {
          status = `Se terminera le ${this.formatDate(
            new Date(subscription.end_at),
          )}`
        }
        return {
          id: subscription.id,
          price: subscription.price
            ? subscription.price.amount_ht.toFixed(2) + '€ HT'
            : '',
          status: status,
          paymentMethod: this.formatPaymentMethodName(
            subscription.payment_method,
          ),
          editable: subscription.editable,
          usable: subscription.usable,
        }
      })
    },
    editedSubscriptionActive() {
      return !this.editSubscriptionForm.active.value
    },
  },
  methods: {
    tokenCreated(token) {
      console.log(token)
    },
    submitPaymentMethod() {
      // this.$refs.stripeElement.submitPaymentMethod()
      this.newPaymentMethodLoading = true
      requestService
        .get(`/api/payment/add-method/${this.addressId}/start`)
        .then(response => {
          const clientSecret = response.intentClientSecret
          return this.$refs.stripeElement.stripe.confirmCardSetup(
            clientSecret,
            {
              // eslint-disable-next-line @typescript-eslint/camelcase
              payment_method: {
                card: this.$refs.stripeElement.element,
              },
            },
          )
        })
        .then(result => {
          console.log(result)
          if (result.error) {
            this.newPaymentMethodError = result.error.message
            return Promise.reject()
          } else {
            return result.setupIntent
          }
        })
        .then(setupIntent => {
          return requestService.post(
            `/api/payment/add-method/${this.addressId}/confirm`,
            {
              stripePaymentMethodId: setupIntent.payment_method,
            },
          )
        })
        .then(response => {
          this.paymentMethods = response.paymentMethods
          this.form.paymentMethod.value = response.paymentMethods[0].id
          this.newPaymentMethodDialog = false
        })
        .finally(() => {
          this.newPaymentMethodLoading = false
        })
    },
    submitSubscriptions() {
      this.submitLoading = true
      requestService
        .post('/api/payment/subscribe', {
          address: this.addressId,
          paymentMethod: this.form.paymentMethod.value,
          quantity: this.form.quantity.value,
        })
        .then(response => {
          this.fetchSubscriptions()
          this.form.quantity.value = 0
          this.confirmationDialog = true
        })
        .catch(error => {
          this.handleServerErrors(error.errors, this.form)
        })
        .finally(() => (this.submitLoading = false))
    },
    fetchAddresses() {
      this.loading = true
      requestService.get('/api/address/').then(response => {
        this.addresses = response
        if (response.length) {
          this.addressId = response[0].id
          this.fetchSubscriptions()
        }
      })
    },
    fetchPaymentMethods() {
      requestService.get('/api/payment/methods').then(response => {
        this.paymentMethods = response
        if (response.length) {
          this.form.paymentMethod.value = response[0].id
        }
      })
    },
    fetchSubscriptions() {
      if (this.addressId === null) {
        this.loading = false
        return
      }
      requestService
        .get(`/api/payment/subscriptions/${this.addressId}`)
        .then(response => {
          this.subscriptions = response.subscriptions
          this.trialDays = response.lastTrialDays
          if (response.subscriptions.length === 0) {
            this.form.quantity.value = 1
          }
          this.loading = false
          this.firstPrice = response.firstPrice.amount_ht
          this.nextPrice = response.nextPrice.amount_ht
          if (response.totalSubscriptionsCount > 0) {
            this.basePrice = response.nextPrice.amount_ht
          } else {
            this.basePrice = response.firstPrice.amount_ht
          }
        })
    },
    formatPaymentMethodName(paymentMethod) {
      return `${this.capitalizeFirstLetter(paymentMethod.brand)} ****${
        paymentMethod.last_digits
      } - ${paymentMethod.expiration_month.toString().padStart(2, 0)}/${
        paymentMethod.expiration_year
      }`
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    formatDate(date, hour = false) {
      return new Intl.DateTimeFormat('fr', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: hour ? 'numeric' : undefined,
        minute: hour ? 'numeric' : undefined,
      }).format(date)
    },
    editSubscription(id) {
      this.editedSubscription = Object.assign(
        {},
        this.subscriptions.find(subscription => subscription.id === id),
      )
      this.editSubscriptionForm.paymentMethod.value = this.editedSubscription.payment_method.id
      this.$nextTick(() => {
        this.$set(
          this.editSubscriptionForm.active,
          'value',
          this.editedSubscription.active,
        )
      })
      this.editSubscriptionDialog = true
    },
    submitEditedSubscription() {
      this.editedSubscriptionLoading = true
      requestService
        .post(
          `/api/payment/subscription/${this.editedSubscription.id}`,
          this.formatDataToSend(this.editSubscriptionForm),
        )
        .then(response => {
          if (response.success) {
            this.$store.dispatch(
              'alert/success',
              'License modifiée avec succès',
            )
            this.editSubscriptionDialog = false
            this.fetchSubscriptions()
          }
        })
        .catch(error => {
          if (error.validationFailed) {
            this.handleServerErrors(error.error, this.editSubscriptionForm)
          }
        })
        .finally(() => (this.editedSubscriptionLoading = false))
    },
    terminate() {
      this.$router.push({ name: 'Home' })
      this.$nextTick(() => location.reload())
    },
  },
  watch: {
    addressId() {
      this.fetchSubscriptions()
    },
  },
  mounted() {
    this.fetchAddresses()
    this.fetchPaymentMethods()
  },
}
</script>

<style scoped></style>
